import React, { useState, useEffect } from "react";
import "./../Home/Home.css";
import Footer from "../Footer/Footer";
import { animated, useTransition } from "react-spring";
// import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import { useSpring } from "react-spring";
import "./../Footer/Footer.css";
import logo from "../../../assets/Images/logo.png";
import { firestore } from "../../../config/firebase";

const Items = ({
  book,
  addToCart,
  user,
  showSignInModal,
  isFlipped,
  onFlip,
}) => {
  const handleAddToCartClick = () => {
    if (user) {
      addToCart(book);
    } else {
      showSignInModal();
    }
  };
  const { transform, opacity } = useSpring({
    opacity: isFlipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${isFlipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  return (
    <animated.div className="book-card" onClick={() => onFlip(book.id)}>
      <div className={`flip-container ${isFlipped ? "flipped" : ""}`}>
        <animated.div
          className="front"
          style={{
            opacity: opacity.interpolate((o) => 1 - o),
            transform,
          }}>
          <img src={book.cover} alt={book.title} width="100" height="150" />
          <h3>{book.title}</h3>
          <p style={{ fontFamily: "sans-serif" }}>{book.size}</p>
          <p className="price">${book.price}</p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleAddToCartClick();
            }}>
            Add to Cart
          </button>
        </animated.div>
        <animated.div
          className="back"
          style={{
            opacity,
            transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
          }}>
          <h2>{book.title}</h2>
          <hr className="title-line" />
          <p>
            <span className="item-title">Size or Color</span>
            <br />
            {book.size}
          </p>
          <p>
            <span className="item-title">Price</span>
            <br /> ${book.price}
          </p>
          <p>
            <span className="item-title">Description</span>
            <br />
            <p className="discription">
              {book.description || "No description available."}
            </p>
          </p>
        </animated.div>
      </div>
    </animated.div>
  );
};

const categories = [
  { id: 5, name: "Beauty" },
  { id: 2, name: "Electronics" },
  { id: 1, name: "Explore", type: "image" },
  { id: 3, name: "Homeware" },
  { id: 4, name: "Fashion" },
];

const Home = ({
  searchQuery,
  addToCart,
  user,
  showSignInModal,
  isCartOpen,
  onToggleCart,
  isOrdersOpen,
  onToggleOrders,
}) => {
  const [booksData, setBooksData] = useState([]);
  const [activeCategory, setActiveCategory] = useState(categories[2]);
  const [flippedBooks, setFlippedBooks] = useState([]);
  const { transform } = useSpring({
    from: { transform: "rotateY(0deg)" },
    to: { transform: flippedBooks ? "rotateY(180deg)" : "rotateY(0deg)" },
    config: { tension: 150, friction: 10 },
  });
  const handleFlip = (bookId) => {
    setFlippedBooks((prevState) => {
      if (prevState.includes(bookId)) {
        return prevState.filter((id) => id !== bookId);
      }
      return [...prevState, bookId];
    });
  };

  const filteredBooks = booksData.filter(
    (book) =>
      book.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (activeCategory.id === 1 || book.category === activeCategory.name)
  );

  const bookTransitions = useTransition(filteredBooks, {
    from: { opacity: 0, transform: "translateY(40px)" },
    enter: { opacity: 1, transform: "translateY(0)" },
    leave: { opacity: 0, transform: "translateY(-40px)" },
    config: { mass: 1, tension: 280, friction: 20 },
    keys: (book) => book.id,
    trail: 25,
  });

  useEffect(() => {
    const cards = document.querySelectorAll(".book-card");
    cards.forEach((card, index) => {
      setTimeout(() => {
        card.classList.add("animate");
      }, index * 200); // Add delay for each card
    });
  }, [filteredBooks]);

  useEffect(() => {
    const fetchBooks = async () => {
      const booksCollection = await firestore.collection("books").get();
      setBooksData(
        booksCollection.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };

    fetchBooks();
  }, []);

  const handleCategoryClick = (category) => {
    if (category.type === "image") {
      setActiveCategory({ id: 1, name: "Shop All" });
      if (isCartOpen) {
        onToggleCart();
      }
      if (isOrdersOpen) {
        onToggleOrders();
      }
    } else if (!category.type) {
      setActiveCategory(category);
      if (isCartOpen) {
        onToggleCart();
      }
      if (isOrdersOpen) {
        onToggleOrders();
      }
    }
  };

  // const scrollBooks = (direction) => {
  //   const container = document.querySelector('.book-container');
  //   const scrollAmount = window.innerWidth * 0.7;

  //   if (direction === 'left') {
  //     container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  //   } else if (direction === 'right') {
  //     container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  //   }
  // };

  return (
    <div>
      <div className="categories">
        <div className="category-buttons">
          {categories.map((category) => {
            // Always render if category type is 'image'
            if (category.type === "image") {
              return (
                <img
                  src={logo}
                  alt="Logo"
                  className="category-logo"
                  onClick={() => handleCategoryClick({ type: "image" })}
                />
              );
            }

            // If neither cart nor orders are open, render the category button
            if (!isCartOpen && !isOrdersOpen) {
              return (
                <button
                  key={category.id}
                  onClick={() => handleCategoryClick(category)}
                  className={activeCategory.id === category.id ? "active" : ""}>
                  {category.name}
                </button>
              );
            }

            // In all other cases, render nothing
            return null;
          })}

          <div className="space"></div>
        </div>
      </div>
      <div className="catagory-name">
        <h1>{activeCategory.name}</h1>
      </div>

      <div className="book-container">
        <div className="padding-start"></div>
        {bookTransitions((props, book) => (
          <animated.div
            key={Math.random()}
            style={props}
            className="book-card-container">
            <Items
              key={book.id}
              book={book}
              addToCart={addToCart}
              user={user}
              showSignInModal={showSignInModal}
              isFlipped={flippedBooks.includes(book.id)}
              onFlip={handleFlip}
              style={{ transform }}
            />
          </animated.div>
        ))}
      </div>

      {/* <div className='scroll-buttons-container'>
          <button className="scroll-button" onClick={() => scrollBooks('left')}>
            <ChevronLeftIcon />
          </button>
          <button className="scroll-button" onClick={() => scrollBooks('right')}>
            <ChevronRightIcon />
          </button>
    </div> */}
      <Footer />
    </div>
  );
};

export default Home;
