import React, { useEffect } from "react";
import "./Cart.css";
import { makeStyles } from "@material-ui/core/styles";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { database } from "../../../config/firebase";

const useStyles = makeStyles((theme) => ({
  paypalButton: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));
const Cart = ({
  user,
  cartItems,
  visible,
  onClose,
  onIncreaseQuantity,
  onDecreaseQuantity,
  onOrderApproval,
  onUpdateOrders,
  onCartQuantityChange,
}) => {
  const classes = useStyles();
  const paypalClientID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
  const handleCloseClick = () => {
    if (onClose) {
      onClose();
    }
  };
  const saveOrderToFirebase = (order, uid) => {
    const ordersRef = database.ref(`orders/${uid}`);
    const newOrderRef = ordersRef.push();
    newOrderRef.set(order);
  };
  const fetchOrdersFromFirebase = (uid, callback) => {
    const ordersRef = database.ref(`orders/${uid}`);
    ordersRef.on("value", (snapshot) => {
      const data = snapshot.val();
      const orders = data ? Object.values(data) : [];
      callback(orders);
    });
  };

  const handleOrderApproval = (data, actions) => {
    return actions.order.capture().then((details) => {
      const order = {
        id: details.id,
        date: new Date().toString(),
        total: total.toFixed(2),
        items: cartItems,
      };
      saveOrderToFirebase(order, user.uid);
      onOrderApproval(details);
    });
  };

  const CartItems = ({ items, onIncreaseQuantity, onDecreaseQuantity }) => {
    return items.length > 0 ? (
      <div>
        {items.map((item) => (
          <div key={item.id} className="cart-item">
            <img src={item.cover} alt={item.title} />
            <div className="cart-item-info">
              <h4>{item.title}</h4>
              <p>{item.size}</p>
              <p>{item.description}</p>
              <p>
                {item.price} x {item.quantity} = ${item.price * item.quantity}
              </p>
            </div>
            <div className="cart-item-quantity">
              <p>Quantity: {item.quantity}</p>
              <button onClick={() => onIncreaseQuantity(item.id)}>+</button>
              <button onClick={() => onDecreaseQuantity(item.id)}>-</button>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <div className="cart-empty">Cart is empty</div>
    );
  };

  const subtotal = cartItems.reduce((acc, item) => {
    return acc + item.price * item.quantity;
  }, 0);

  const tax = 0.1 * subtotal;
  const shipping = 5.0;
  const total = subtotal + tax + shipping;
  useEffect(() => {
    if (user) {
      fetchOrdersFromFirebase(user.uid, (orders) => {
        onUpdateOrders(orders); // Add this line
      });
    }
  }, [user, onUpdateOrders]);

  useEffect(() => {
    let totalQuantity = cartItems.reduce((acc, item) => acc + item.quantity, 0);
    if (onCartQuantityChange) {
      onCartQuantityChange(totalQuantity);
    }
  }, [cartItems]);

  return (
    <div className={`cart${visible ? " cart-visible" : ""}`}>
      <div className="cart-nav">
        <button className="close-cart" onClick={handleCloseClick}>
          Close
        </button>
      </div>
      <div className="cart-header">
        <h2 className="cart-title">Shopping Cart</h2>
      </div>
      <CartItems
        items={cartItems}
        onIncreaseQuantity={onIncreaseQuantity}
        onDecreaseQuantity={onDecreaseQuantity}
      />
      {cartItems.length > 0 && (
        <div className="cart-summary">
          <p>Subtotal: ${subtotal.toFixed(2)}</p>
          <p>Tax: ${tax.toFixed(2)}</p>
          <p>Shipping: ${shipping.toFixed(2)}</p>
          <p>Total: ${total.toFixed(2)}</p>
          <div className={classes.flexSpacer} />
          <PayPalScriptProvider
            options={{
              "client-id": paypalClientID,
              components: "buttons",
              currency: "USD",
            }}>
            <div className={classes.paypalButton}>
              <PayPalButtons
                key={total.toFixed(2)}
                createOrder={(data, actions) => {
                  return actions.order
                    .create({
                      purchase_units: [
                        {
                          amount: {
                            currency_code: "USD",
                            value: total.toFixed(2),
                          },
                        },
                      ],
                    })
                    .then((orderId) => {
                      return orderId;
                    });
                }}
                onApprove={handleOrderApproval}
              />
            </div>
          </PayPalScriptProvider>
        </div>
      )}
    </div>
  );
};

export default Cart;
