import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import "../Navbar/Navbar.css";
import SignUpModal from "../SignUpModal/SignUpModal";
import SignInModal from "../SignInModal/SignInModal";
import { auth } from "../../../config/firebase";
import { AiOutlineShoppingCart } from "react-icons/ai";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "../TermsConditions/TermsConditions";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const Navbar = ({
  onSearchQueryChange,
  onToggleCart,
  onToggleOrders,
  isCartOpen,
  isOrdersOpen,
  cartQuantity,
}) => {
  const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState(false);
  const [termsConditionsVisible, setTermsConditionsVisible] = useState(false);

  const openPrivacyPolicy = () => setPrivacyPolicyVisible(true);
  const closePrivacyPolicy = () => setPrivacyPolicyVisible(false);
  const openTermsConditions = () => setTermsConditionsVisible(true);
  const closeTermsConditions = () => setTermsConditionsVisible(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [signUpModalVisible, setSignUpModalVisible] = useState(false);
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setDropdownVisible(false));

  const handleSearchChange = (e) => {
    if (onSearchQueryChange) {
      onSearchQueryChange(e.target.value);
    }
  };
  const handleOrdersClick = () => {
    if (onToggleOrders) {
      onToggleOrders();
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const openSignUpModal = () => {
    setSignUpModalVisible(true);
    setDropdownVisible(false);
  };
  const handleLogoClick = () => {
    window.location.reload();
    if (isCartOpen && onToggleCart) {
      onToggleCart();
    }
    if (isOrdersOpen && onToggleOrders) {
      onToggleOrders();
    }
  };
  const closeSignUpModal = () => {
    setSignUpModalVisible(false);
  };

  const openSignInModal = () => {
    setSignInModalVisible(true);
    setDropdownVisible(false);
  };

  const closeSignInModal = () => {
    setSignInModalVisible(false);
  };

  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {
        setDropdownVisible(false);
        window.location.reload();
      })
      .catch((error) => {
        // Handle errors here, such as displaying a notification to the user
        console.error("Error signing out:", error);
      });
    if (isCartOpen && onToggleCart) {
      onToggleCart();
    }
    if (isOrdersOpen && onToggleOrders) {
      onToggleOrders();
    }
  };

  const handleUserSignUp = (updatedUser) => {
    setCurrentUser(updatedUser);
    setSignUpModalVisible(false);
  };

  const handleUserSignIn = (userData) => {
    setCurrentUser(userData);
    setSignInModalVisible(false);
  };
  const handleCartClick = () => {
    if (onToggleCart) {
      onToggleCart();
    }
  };

  const renderInitials = () => {
    if (currentUser) {
      return currentUser.displayName
        ? currentUser.displayName.charAt(0).toUpperCase()
        : currentUser.email.charAt(0).toUpperCase();
    }
  };

  return (
    <nav className="navbar">
      <div className="left-items">
        <ul className="nav-items">
          <li>
            <Link to="/">
              <div className="logo-container">
                <img
                  src={"./logo.png"}
                  alt="Logo"
                  className="logo"
                  onClick={handleLogoClick}
                />
              </div>
            </Link>
          </li>
          <li>
            <Link to="#" onClick={handleCartClick}>
              <div className="logo-cart-container">
                <AiOutlineShoppingCart size={38} />
                {cartQuantity > 0 && (
                  <span className="cart-quantity">{cartQuantity}</span>
                )}
              </div>
            </Link>
          </li>
        </ul>
      </div>
      <div className="center-item">
        <input
          type="text"
          className="search-bar"
          placeholder="Search books..."
          onChange={handleSearchChange}
        />
      </div>
      <div className="right-items"></div>
      <div className="profile-icon" onClick={toggleDropdown}>
        {currentUser ? (
          <>
            <FaUserCircle
              size={30}
              className={`desktop-icon${isMobile ? " hidden" : ""}`}
            />
            <span className={`mobile-initials${!isMobile ? " hidden" : ""}`}>
              {renderInitials()}
            </span>
          </>
        ) : (
          <FaUserCircle size={30} />
        )}

        {currentUser && (
          <span className={`user-name${isMobile ? " mobile-initials" : ""}`}>
            {currentUser.displayName
              ? currentUser.displayName.charAt(0).toUpperCase() +
                currentUser.displayName.slice(1)
              : currentUser.email.charAt(0).toUpperCase() +
                currentUser.email.split("@")[0].slice(1)}
          </span>
        )}

        {dropdownVisible && (
          <ul className="dropdown-menu" ref={dropdownRef}>
            {currentUser ? (
              <>
                <li>
                  <Link to="#" onClick={handleOrdersClick}>
                    Orders
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={handleSignOut}>
                    Logout
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={openPrivacyPolicy}>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={openTermsConditions}>
                    Terms & Conditions
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="#" onClick={openSignInModal}>
                    Sign In
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={openSignUpModal}>
                    Sign Up
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={openPrivacyPolicy}>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={openTermsConditions}>
                    Terms & Conditions
                  </Link>
                </li>
              </>
            )}
          </ul>
        )}
      </div>
      <SignUpModal
        isOpen={signUpModalVisible}
        onRequestClose={closeSignUpModal}
        onUserSignUp={(updatedUser) => handleUserSignUp(updatedUser)}
      />
      <SignInModal
        isOpen={signInModalVisible}
        onRequestClose={closeSignInModal}
        onUserSignIn={handleUserSignIn}
      />
      <PrivacyPolicy
        isOpen={privacyPolicyVisible}
        onRequestClose={closePrivacyPolicy}
      />
      <TermsConditions
        isOpen={termsConditionsVisible}
        onRequestClose={closeTermsConditions}
      />
    </nav>
  );
};

export default Navbar;
