import React, { useState, useEffect } from "react";
import { storage, firestore } from "../../../config/firebase";
import "./../UploadItems/UploadItems.css";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";

const UploadItems = () => {
  const [title, setTitle] = useState("");
  const [size, setSize] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [books, setBooks] = useState([]);
  const [bookToEdit, setBookToEdit] = useState(null);
  const CATEGORIES = ["Beauty", "Electronics", "Fashion", "Homeware"];
  const [filename, setFilename] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const snapshot = await firestore.collection("books").get();
        const fetchedBooks = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBooks(fetchedBooks);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };
    fetchBooks();
  }, []);

  const handleUpload = async () => {
    try {
      setUploadStatus("Uploading...");

      let imageUrl = bookToEdit ? bookToEdit.cover : "";

      if (image) {
        const storageRef = storage.ref(`covers/${image.name}`);
        await storageRef.put(image);
        imageUrl = await storageRef.getDownloadURL();
      }

      const bookData = {
        title,
        size,
        price: parseFloat(String(price).replace("$", "")),
        category,
        cover: imageUrl,
        description,
      };

      if (bookToEdit) {
        await firestore.collection("books").doc(bookToEdit.id).update(bookData);
        // Updating the local state for the edited book
        setBooks((prevBooks) =>
          prevBooks.map((book) =>
            book.id === bookToEdit.id ? { ...book, ...bookData } : book
          )
        );
        setBookToEdit(null);
      } else {
        const newBookRef = await firestore.collection("books").add(bookData);
        // Optimistically updating the local state with the new book
        const newBook = {
          id: newBookRef.id,
          ...bookData,
        };
        setBooks((prevBooks) => [...prevBooks, newBook]);
      }

      // Clearing the form
      setTitle("");
      setSize("");
      setPrice("");
      setCategory("");
      setImage(null);
      setFilename("");
      setDescription("");

      setUploadStatus("Successfully uploaded");
    } catch (error) {
      console.error("Error uploading book:", error);
      setUploadStatus("Failed to upload");
    }
  };

  const handleDelete = async (book) => {
    try {
      await firestore.collection("books").doc(book.id).delete();
      const storageRef = storage.refFromURL(book.cover);
      await storageRef.delete();
      setBooks((prevBooks) => prevBooks.filter((b) => b.id !== book.id));
    } catch (error) {
      console.error("Error deleting book:", error);
    }
  };

  const handleEdit = (book) => {
    setTitle(book.title);
    setSize(book.size);
    setPrice(book.price);
    setCategory(book.category);
    setDescription(book.description || "");
    setImage(null); // Clearing out any previously selected image
    setBookToEdit(book);
  };

  return (
    <div className="upload-container">
      <TextField
        fullWidth
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />

      <TextField
        fullWidth
        label="Size or Color"
        value={size}
        onChange={(e) => setSize(e.target.value)}
      />

      <TextField
        fullWidth
        label="Price"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />

      <FormControl fullWidth>
        <InputLabel>Category</InputLabel>
        <Select value={category} onChange={(e) => setCategory(e.target.value)}>
          {CATEGORIES.map((cat, index) => (
            <MenuItem key={index} value={cat}>
              {cat}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Description"
        multiline
        rows={4}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <input
        type="file"
        id="file-input"
        onChange={(e) => {
          setImage(e.target.files[0]);
          setFilename(e.target.files[0] ? e.target.files[0].name : "");
        }}
      />
      <label htmlFor="file-input">
        <Button
          variant="contained"
          color="primary"
          component="span"
          style={{ backgroundColor: "#0c2a45", color: "#e6c629" }}>
          Choose File
        </Button>
      </label>
      {filename && <p>Selected File: {filename}</p>}
      <Button variant="contained" color="primary" onClick={handleUpload}>
        {bookToEdit ? "Update" : "Upload"}
      </Button>

      {uploadStatus && <p>{uploadStatus}</p>}

      {/* Display section */}
      <div className="uploaded-items">
        <h2>Uploaded Items:</h2>
        <ul>
          {books.map((book) => (
            <li key={book.id}>
              <img src={book.cover} alt={book.title} width="50" />
              <p>
                {book.title} - {book.size} - ${book.price} - {book.category}
              </p>
              <div className="edit-delete-button">
                <button
                  onClick={() => handleEdit(book)}
                  className="uploaded-items-button-edit-btn">
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(book)}
                  className="uploaded-items-button-delete-btn">
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UploadItems;
