import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import "./../AdminNav/AdminNav.css";
import { auth } from "../../../config/firebase";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const Navbar = ({ onSearchQueryChange, onToggleOrders }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setDropdownVisible(false));

  const handleSearchChange = (e) => {
    if (onSearchQueryChange) {
      onSearchQueryChange(e.target.value);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogoClick = () => {
    window.location.reload();
  };

  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {
        setDropdownVisible(false);
        window.location.reload();
      })
      .catch((error) => {
        // Handle errors here, such as displaying a notification to the user
        console.error("Error signing out:", error);
      });
  };

  const renderInitials = () => {
    if (currentUser) {
      return currentUser.displayName
        ? currentUser.displayName.charAt(0).toUpperCase()
        : currentUser.email.charAt(0).toUpperCase();
    }
  };

  return (
    <nav className="navbar">
      <div className="left-items">
        <ul className="nav-items">
          <li>
            <Link to="/">
              <div className="logo-container">
                <img
                  src={"./logo.png"}
                  alt="Logo"
                  className="logo-admin"
                  onClick={handleLogoClick}
                />
              </div>
            </Link>
          </li>
          {currentUser && (
            <>
              <li>
                <div className="user-button">
                  <Link to="/users">Manage Items</Link>
                </div>
              </li>
              <li>
                <div className="user-button">
                  <Link to="/admin-home">Orders</Link>
                </div>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className="center-item">
        <input
          type="text"
          className="search-bar-admin"
          placeholder="Search books..."
          onChange={handleSearchChange}
        />
      </div>
      <div className="right-items"></div>
      <div className="profile-icon" onClick={toggleDropdown}>
        {currentUser ? (
          <>
            <FaUserCircle
              size={30}
              className={`desktop-icon${isMobile ? " hidden" : ""}`}
            />
            <span className={`mobile-initials${!isMobile ? " hidden" : ""}`}>
              {renderInitials()}
            </span>
          </>
        ) : (
          <FaUserCircle size={30} />
        )}

        {currentUser && (
          <span className={`user-name${isMobile ? " mobile-initials" : ""}`}>
            {currentUser.displayName
              ? currentUser.displayName.charAt(0).toUpperCase() +
                currentUser.displayName.slice(1)
              : currentUser.email.charAt(0).toUpperCase() +
                currentUser.email.split("@")[0].slice(1)}
          </span>
        )}

        {dropdownVisible && (
          <ul className="dropdown-menu-admin" ref={dropdownRef}>
            {currentUser ? (
              <>
                <li className="upload-item-admin">
                  <Link to="/users">Manage Item</Link>
                </li>
                <li className="orders-item-admin">
                  <Link to="/admin-home">Orders</Link>
                </li>
                <li>
                  <Link to="#" onClick={handleSignOut}>
                    Logout
                  </Link>
                </li>
              </>
            ) : (
              <></>
            )}
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
