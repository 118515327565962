import React from "react";
import "./Orders.css";

const Orders = ({ visible, onClose, orders }) => {
  const handleCloseClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const OrderItems = ({ items }) => {
    return (
      <div>
        {items.map((item) => (
          <div key={item.id} className="order-item">
            <img src={item.cover} alt={item.title} className="order-item-img" />
            <div className="order-item-desc">
              <div className="orders-item-info">
                <h4>{item.title}</h4>
                <p>{item.description}</p>
                <p>Quantity: {item.quantity}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const sortedOrders = [...orders].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return (
    <div className={`orders${visible ? " orders-visible" : ""}`}>
      <div className="orders-nav">
        <button className="close-orders" onClick={handleCloseClick}>
          Close
        </button>
      </div>
      <div className="orders-header">
        <h2 className="orders-title">Your Orders</h2>
      </div>
      {sortedOrders.length === 0 ? (
        <div className="orders-empty">You have no orders yet!</div>
      ) : (
        sortedOrders.map((order) => (
          <div key={order.id} className="orders-container">
            <h4>Order ID: {order.id}</h4>
            <p>Order Date: {new Date(order.date).toLocaleDateString()}</p>
            <p>Total: ${order.total}</p>
            <OrderItems items={order.items} />
          </div>
        ))
      )}
    </div>
  );
};
export default Orders;
