import React from "react";
import "../Home/Home.css";

const PrivacyPolicy = ({ isOpen, onRequestClose }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className="cart-nav">
          <button className="close-cart" onClick={onRequestClose}>
            {" "}
            Close
          </button>
        </div>
        {/* <button className="close-popup" onClick={onRequestClose}>X</button>     */}
        <h2>Privacy Policy</h2>

        <p>Last updated: 4/20/2023</p>
        <br />
        <p>
          The Book Store ("us", "we", or "our") operates online book store
          platform (the "Service"). This page informs you of our policies
          regarding the collection, use, and disclosure of personal information
          when you use our Service.
        </p>
        <br />
        <p>
          We will not use or share your information with anyone except as
          described in this Privacy Policy.
        </p>
        <br />
        <p>
          By using the Service, you agree to the collection and use of
          information in accordance with this policy.
        </p>

        <h2>Information Collection and Use</h2>
        <br />
        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information may include, but is
          not limited to, your name, email address, postal address, and phone
          number ("Personal Information").
        </p>
        <br />
        <p>
          We collect this information for the purpose of providing the Service,
          identifying and communicating with you, responding to your requests or
          inquiries, and improving our Service.
        </p>

        <h2>Log Data</h2>
        <br />
        <p>
          We collect information that your browser sends whenever you visit our
          Service ("Log Data"). This Log Data may include information such as
          your computer's Internet Protocol ("IP") address, browser type,
          browser version, the pages of our Service that you visit, the time and
          date of your visit, the time spent on those pages, and other
          statistics.
        </p>
        <br />
        <p>
          In addition, we may use third-party services such as Google Analytics
          that collect, monitor, and analyze this type of information to enhance
          the functionality of our Service. These third-party service providers
          have their own privacy policies addressing how they use such
          information.
        </p>

        <h2>Cookies</h2>
        <br />
        <p>
          Cookies are files with a small amount of data, which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and transferred to your device. We use cookies to collect
          information to improve our Service.
        </p>
        <br />
        <p>
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. The "Help" feature on most browsers
          provides information on how to accept cookies, disable cookies, or to
          notify you when receiving a new cookie.
        </p>
        <br />
        <p>
          If you do not accept cookies, you may not be able to use some features
          of our Service, and we recommend that you leave them turned on.
        </p>

        <h2>Service Providers</h2>
        <br />
        <p>
          We may employ third-party companies and individuals to facilitate our
          Service, provide the Service on our behalf, perform Service-related
          services, or assist us in analyzing how our Service is used.
        </p>

        <p>
          These third parties have access to your Personal Information only to
          perform these tasks on our behalf and are obligated not to disclose or
          use it for any other purpose.
        </p>

        <h2>Security</h2>
        <br />
        <p>
          The security of your Personal Information is important to us, but
          remember that no method of transmission over the Internet or method of
          electronic storage is 100% secure. While we strive to use commercially
          acceptable means to protect your Personal Information, we cannot
          guarantee its absolute security.
        </p>

        <h2>Links to Other Sites</h2>
        <br />
        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third-party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>

        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies, or practices of any third-party sites or services.
        </p>

        <h2>Children's Privacy</h2>
        <br />
        <p>
          Our Service does not address anyone under the age of 13 ("Children").
          We do not knowingly collect personally identifiable information from
          children under 13. If you are a parent or guardian and you learn that
          your Children have provided us with Personal Information, please
          contact us. If we become aware that we have collected Personal
          Information from a child under age 13 without verification of parental
          consent, we take steps to remove that information from our servers.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <br />
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>

        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
