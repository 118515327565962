import React from "react";
import Modal from "react-modal";
import { makeStyles } from "@material-ui/core/styles";
import "./CheckoutModal.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paypalButton: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: "#e6c629",
    color: "#fff",
    minWidth: "unset",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    position: "relative",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    width: "100%",
    alignItems: "center",
  },
  flexSpacer: {
    flexGrow: 1,
  },
}));

Modal.setAppElement("#root");

const CheckoutModal = ({ isOpen, onRequestClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onRequestClose}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="checkout-modal-title"
      className={classes.modal}>
      <DialogTitle>Order Received</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {" "}
          Thank you for your order! Your order has been successfully placed.{" "}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} className={classes.closeButton}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckoutModal;
