import React, { useState, useEffect } from "react";
import { auth, database } from "../../../config/firebase";
import "./AdminHome.css";

const fetchUsernameByUID = async (uid) => {
  const userRef = database.ref(`users/${uid}`);
  const snapshot = await userRef.once("value");
  const userData = snapshot.val();
  return userData ? userData.displayName : "Unknown User";
};
const fetchEmailByUID = async (uid) => {
  const userRef = database.ref(`users/${uid}`);
  const snapshot = await userRef.once("value");
  const userData = snapshot.val();
  return userData ? userData.email : "Email not provided";
};

const AdminHome = () => {
  const [orders, setOrders] = useState([]);
  const [isAdmin, setIsAdmin] = useState(null);

  useEffect(() => {
    console.log("Inside useEffect");
    const currentUser = auth.currentUser;

    if (currentUser) {
      const adminRef = database.ref(`admins/${currentUser.uid}`);
      console.log("Current user:", auth.currentUser);
      adminRef
        .once("value")
        .then((snapshot) => {
          console.log("Inside admin check");
          if (snapshot.exists()) {
            console.log("Snapshot:", snapshot.val());
            setIsAdmin(true);
            // Fetch orders only if user is an admin

            const ordersRef = database.ref("orders");
            ordersRef.on("value", async (snapshot) => {
              const ordersData = snapshot.val();
              let allOrders = [];
              if (ordersData) {
                for (let uid of Object.keys(ordersData)) {
                  const userOrders = ordersData[uid];
                  const username = await fetchUsernameByUID(uid);
                  const email = await fetchEmailByUID(uid);
                  for (let orderKey of Object.keys(userOrders)) {
                    const orderDetails = userOrders[orderKey];
                    allOrders.push({
                      id: orderKey,
                      username: username,
                      email: email,
                      ...orderDetails,
                    });
                  }
                }
                allOrders.sort(
                  (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime()
                );

                setOrders(allOrders);
              }
            });

            // Return a cleanup function to detach the listener
            return () => ordersRef.off();
            adminRef.off();
          } else {
            console.log("Logged in user UID:", currentUser.uid);
            setIsAdmin(false);
          }
        })
        .catch((error) => {
          console.error("Error checking admin rights: ", error);
        });
    }
  }, []);

  return (
    <div className="admin-orders">
      <div className="orders-header">
        <h2 className="orders-title">All Orders</h2>
      </div>
      {orders.length === 0 ? (
        <div className="orders-empty">There are no orders yet!</div>
      ) : (
        orders.map((order) => (
          <div key={order.id} className="orders-container">
            <h4>Order ID: {order.id}</h4>
            <p>Customer Name: {order.username}</p>
            <p>Customer Email: {order.email}</p>
            <p>Order Date: {new Date(order.date).toLocaleDateString()}</p>
            <p>Total: ${order.total}</p>
            {Array.isArray(order.items) && <OrderItems items={order.items} />}
          </div>
        ))
      )}
    </div>
  );
};

const OrderItems = ({ items }) => {
  return (
    <div>
      {items.map((item) => (
        <div key={item.id} className="order-item">
          <img src={item.cover} alt={item.title} className="order-item-img" />
          <div className="order-item-desc">
            <h4>{item.title}</h4>
            <p>{item.description}</p>
            <p>Quantity: {item.quantity}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AdminHome;
