import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavBar from "./components/NonAdmin/Navbar/Navbar";
import Home from "./components/NonAdmin/Home/Home";
import Cart from "./components/NonAdmin/Cart/Cart";
import { auth, database } from "./config/firebase";
import SignInModal from "./components/NonAdmin/SignInModal/SignInModal";
import CheckoutModal from "./components/NonAdmin/CheckOutModal/CheckoutModal";
import Orders from "./components/NonAdmin/Order/Orders";
import "./App.css";
import "./components/Admin/AdminHome/AdminHome";
import bannerImage1 from "../src/assets/Images/BookB.svg";
import bannerImage2 from "../src/assets/Images/Book_Store.svg";
import AdminHome from "./components/Admin/AdminHome/AdminHome";
import AdminNav from "./components/Admin/AdminNav/AdminNav";
import UploadItems from "./components/Admin/UploadItems/UploadItems";

const App = () => {
  const [user, setUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const [checkoutModalVisible, setCheckoutModalVisible] = useState(false);
  const [orders, setOrders] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isOrdersOpen, setIsOrdersOpen] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(0);
  const bannerImages = [bannerImage2, bannerImage1];
  const [isAdmin, setIsAdmin] = useState(false);
  const adminsRef = database.ref("admins");

  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [bannerSrc, setBannerSrc] = useState(bannerImages[currentBannerIndex]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setBannerSrc(bannerImage2);
    }, 40000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const changeBanner = () => {
    const newIndex = (currentBannerIndex + 1) % bannerImages.length;
    setCurrentBannerIndex(newIndex);
    setBannerSrc(bannerImages[newIndex]);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        // Check if the authenticated user is an admin
        adminsRef.child(user.uid).once("value", (snapshot) => {
          if (snapshot.exists() && snapshot.val() === true) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        });
      } else {
        setUser(null);
        setIsAdmin(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user) {
      const cartItemsRef = database.ref(`users/${user.uid}/cartItems`);
      cartItemsRef.on("value", (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setCartItems(data);
        } else {
          setCartItems([]);
        }
      });
      return () => {
        cartItemsRef.off();
      };
    }
  }, [user]);

  const addToCart = (book) => {
    console.log("Adding to cart:", book);
    const existingItem = cartItems.find((item) => item.id === book.id);

    if (existingItem) {
      const updatedCartItems = cartItems.map((item) =>
        item.id === book.id ? { ...item, quantity: item.quantity + 1 } : item
      );
      setCartItems(updatedCartItems);
      database.ref(`users/${user.uid}/cartItems`).set(updatedCartItems);
    } else {
      const newCartItems = [...cartItems, { ...book, quantity: 1 }];
      setCartItems(newCartItems);
      database.ref(`users/${user.uid}/cartItems`).set(newCartItems);
    }
  };

  const handleOrderApproval = (details) => {
    setCartItems([]);
    database.ref(`users/${user.uid}/cartItems`).set([]);
    setCheckoutModalVisible(true);
  };

  const handleDecreaseQuantity = (id) => {
    const updatedCartItems = cartItems
      .map((item) =>
        item.id === id ? { ...item, quantity: item.quantity - 1 } : item
      )
      .filter((item) => item.quantity > 0);

    setCartItems(updatedCartItems);
    database.ref(`users/${user.uid}/cartItems`).set(updatedCartItems);
  };

  const handleSignOut = () => {
    setUser(null);
  };
  const toggleCartOpen = () => {
    setIsCartOpen(!isCartOpen);
  };

  const toggleOrdersOpen = () => {
    setIsOrdersOpen(!isOrdersOpen);
  };
  const handleAddToCart = (book) => {
    console.log("Handling add to cart:", book);
    if (user) {
      addToCart(book);
      // setIsCartOpen(true);
    } else {
      showSignInModal();
    }
  };

  const handleIncreaseQuantity = (id) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === id ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedCartItems);
    database.ref(`users/${user.uid}/cartItems`).set(updatedCartItems);
  };

  const handleCheckout = () => {
    database.ref(`users/${user.uid}/cartItems`).set([]);
  };

  const showSignInModal = () => {
    setSignInModalVisible(true);
  };

  return (
    <Router>
      <div className="App">
        {isAdmin ? (
          <div>
            <AdminNav
              user={user}
              onSignOut={handleSignOut}
              onSearchQueryChange={setSearchQuery}
            />
            <Switch>
              <Route path="/users" component={UploadItems} />
              <Route path="/admin-home" component={AdminHome} />
              <Route exact path="/" component={AdminHome} />
            </Switch>
          </div>
        ) : (
          <React.Fragment>
            <NavBar
              user={user}
              onSignOut={handleSignOut}
              onSearchQueryChange={setSearchQuery}
              onToggleCart={toggleCartOpen}
              onToggleOrders={toggleOrdersOpen}
              isCartOpen={isCartOpen}
              isOrdersOpen={isOrdersOpen}
              cartQuantity={cartQuantity}
            />
            <div className="banner-container">
              <img src={bannerSrc} alt="Books" className="books-banner" />
              <div className="banner-dots">
                {bannerImages.map((_, index) => (
                  <div
                    key={index}
                    className={`banner-dot ${
                      index === currentBannerIndex ? "active" : ""
                    }`}
                    onClick={() => changeBanner(index)}></div>
                ))}
              </div>
            </div>
            <Home
              searchQuery={searchQuery}
              addToCart={handleAddToCart}
              cartItems={cartItems}
              user={user}
              showSignInModal={showSignInModal}
              onToggleCart={toggleCartOpen}
              onToggleOrders={toggleOrdersOpen}
              isCartOpen={isCartOpen}
              isOrdersOpen={isOrdersOpen}
            />
            <Cart
              user={user}
              cartItems={cartItems}
              visible={isCartOpen}
              onClose={toggleCartOpen}
              onIncreaseQuantity={handleIncreaseQuantity}
              onDecreaseQuantity={handleDecreaseQuantity}
              onCheckout={handleCheckout}
              onOrderApproval={handleOrderApproval}
              onUpdateOrders={setOrders}
              onCartQuantityChange={(quantity) => setCartQuantity(quantity)}
            />
            <Orders
              visible={isOrdersOpen}
              onClose={toggleOrdersOpen}
              orders={orders}
            />
            <CheckoutModal
              isOpen={checkoutModalVisible}
              onRequestClose={() => setCheckoutModalVisible(false)}
            />
            <SignInModal
              isOpen={signInModalVisible}
              onRequestClose={() => setSignInModalVisible(false)}
            />
          </React.Fragment>
        )}
      </div>
    </Router>
  );
};

export default App;
